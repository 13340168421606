/**
 * Constant variables
 */

// 每页数量
export const PAGE_SIZE = 10;

// 初始页码
export const INITIAL_PAGE_NUM = 1;

// 默认 toast 关闭时间（单位：s）
export const DEFAULT_TOAST_DURATION = 2;

// 跳转的协议的前一部分，如 qparkstoreapp://openapp 中的 qparkstoreapp
export const SCHEMA = '';

// 跳转的协议的中间一部分，如 qparkstoreapp://openapp 中的 openapp
export const METHODS = {
  PUSH_WEB: '', // pushToWeb,
  LOGIN: '', // login
};

export const AUTH_TOKEN_KEY = '';
