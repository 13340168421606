/* eslint-disable no-template-curly-in-string */
export default {
  中国: '中国',
  美国: '美国',
  简体中文: '简体中文',
  英文: '英文',
  学饱: '学饱',
  智能语音互动玩偶: '智能语音互动玩偶',
  '不只是玩具，更是朋友': '不只是玩具，更是朋友',
  '让科技更有温度，让陪伴更加智能': '让科技更有温度，让陪伴更加智能',
  AI玩偶: 'AI玩偶',
  家庭的智能新伙伴: '家庭的智能新伙伴',
  智能陪伴: '智能陪伴',
  随时享受智能沟通: '随时享受智能沟通',
  超强记忆力: '超强记忆力',
  点滴美好的见证: '点滴美好的见证',
  个性化服务: '个性化服务',
  满足不同家庭成员需求: '满足不同家庭成员需求',
  让每一次互动都充满惊喜: '让每一次互动都充满惊喜',
  '智能玩伴  乐趣无限': '智能玩伴  乐趣无限',
  '学饱玩偶通过与家庭成员的日常对话、互动和游戏，既丰富了孩子们的想象力和创造力，又增进了家人间的互动，让日常生活变得更加生动有趣。':
    '学饱玩偶通过与家庭成员的日常对话、互动和游戏，既丰富了孩子们的想象力和创造力，又增进了家人间的互动，让日常生活变得更加生动有趣。',
  '倾听与理解 寓教于乐': '倾听与理解 寓教于乐',
  '学饱玩偶陪伴儿童成长，关注儿童的对话和情绪变化，理解他们天马行空的奇特想法，倾听他们的感受，及时提供帮助，给予正面心理抚慰与建议。':
    '学饱玩偶陪伴儿童成长，关注儿童的对话和情绪变化，理解他们天马行空的奇特想法，倾听他们的感受，及时提供帮助，给予正面心理抚慰与建议。',
  '以互动游戏和生动故事的方式，引导孩子积极向上，乐观面对生活。':
    '以互动游戏和生动故事的方式，引导孩子积极向上，乐观面对生活。',
  '多场景对话  个性化服务': '多场景对话  个性化服务',
  '学饱玩偶集成了专业咨询、日程管理、天气查询等多功能服务，简化了日常生活，提升了工作效率。根据不同家庭成员的需求提供个性化服务，确保每项任务都能及时、有序地完成，让日常管理变得轻松便捷。':
    '学饱玩偶集成了专业咨询、日程管理、天气查询等多功能服务，简化了日常生活，提升了工作效率。根据不同家庭成员的需求提供个性化服务，确保每项任务都能及时、有序地完成，让日常管理变得轻松便捷。',
  '学饱玩偶将通过日常对话捕捉家庭成员的独特特征，基于这些“画像”，提供更加个性化的互动体验，让每个家庭成员都感受到被特别对待。':
    '学饱玩偶将通过日常对话捕捉家庭成员的独特特征，基于这些“画像”，提供更加个性化的互动体验，让每个家庭成员都感受到被特别对待。',
  '记录欢笑  珍藏点滴时刻': '记录欢笑  珍藏点滴时刻',
  '学饱玩偶作为家里的的贴心小伙伴，拥有人类一般的超强记忆力，将日常生活中的点点滴滴记在脑海，珍藏日常生活中的每个高光时刻。':
    '学饱玩偶作为家里的的贴心小伙伴，拥有人类一般的超强记忆力，将日常生活中的点点滴滴记在脑海，珍藏日常生活中的每个高光时刻。',
  '学饱玩偶能够在谈话时自然的回忆起上次家庭聚会时的趣事，或是提起某个家庭成员的生日，甚至是那次户外旅行中的欢笑。也能够根据家庭成员的喜好和过往的对话，引发新的话题，让每一次的交流都充满新鲜感和亲切感。':
    '学饱玩偶能够在谈话时自然的回忆起上次家庭聚会时的趣事，或是提起某个家庭成员的生日，甚至是那次户外旅行中的欢笑。也能够根据家庭成员的喜好和过往的对话，引发新的话题，让每一次的交流都充满新鲜感和亲切感。',
  让学饱玩偶: '让学饱玩偶',
  '成为你的快乐源泉!': '成为你的快乐源泉!',
  '学饱，智能语音互动玩偶，将人工智能的力量带入您的日常生活，随时与您交流并提供帮助。':
    '学饱，智能语音互动玩偶，将人工智能的力量带入您的日常生活，随时与您交流并提供帮助。',
  'Copyright © 2024 . 厦门绽放好物科技有限公司版权所有': 'Copyright © 2024 . 厦门绽放好物科技有限公司版权所有',
  '联系我们：${email}': '联系我们：${email}',
};
