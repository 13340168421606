import React, { useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { DEFAULT_TOAST_DURATION } from '@/constants';

const Loading: React.FC<{ duration?: number }> = (props?: { duration?: number }) => {
  useEffect(() => {
    Toast.show({
      icon: 'loading',
      content: '加载中…',
      duration: props?.duration || DEFAULT_TOAST_DURATION,
    })
    return () => Toast.clear();
  }, []);

  return null;
};

export default Loading;
