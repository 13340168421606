/* eslint-disable no-template-curly-in-string */
export default {
  中国: 'China',
  美国: 'USA',
  简体中文: 'Simplified Chinese',
  英文: 'English',
  学饱: 'XUEBAO',
  智能语音互动玩偶: 'General Intelligence Robotics',
  '不只是玩具，更是朋友': 'More than a toy, a friend for life.',
  '让科技更有温度，让陪伴更加智能': 'Warm tech, smart companionship.',
  AI玩偶: 'AI Doll',
  家庭的智能新伙伴: 'Smart Home Pal',
  智能陪伴: 'AI Friend',
  随时享受智能沟通: 'Smart Chat anytime',
  超强记忆力: 'Ultra Memory',
  点滴美好的见证: 'Memories at a Glance',
  个性化服务: 'Tailored Service',
  满足不同家庭成员需求: 'Meets All Needs',
  让每一次互动都充满惊喜: 'Bring Surprise to Every Interaction',
  '智能玩伴  乐趣无限': 'IntelliPal  Endless Fun',
  '学饱玩偶通过与家庭成员的日常对话、互动和游戏，既丰富了孩子们的想象力和创造力，又增进了家人间的互动，让日常生活变得更加生动有趣。':
    "The XUEBAO doll enriches children's imagination and creativity through daily conversations, interactions, and games with family members, while also enhancing family interactions and making everyday life more lively and interesting.",
  '倾听与理解 寓教于乐': 'Listening and Understanding Learning with Fun',
  '学饱玩偶陪伴儿童成长，关注儿童的对话和情绪变化，理解他们天马行空的奇特想法，倾听他们的感受，及时提供帮助，给予正面心理抚慰与建议。':
    'The XUEBAO doll accompanies children as they grow, paying attention to their conversations and emotional changes, understanding their imaginative and whimsical ideas, listening to their feelings, providing timely help, and offering positive psychological comfort and advice.',
  '以互动游戏和生动故事的方式，引导孩子积极向上，乐观面对生活。':
    'Guide children to be positive and optimistic about life through interactive games and vivid stories.',
  '多场景对话  个性化服务': 'Capture Joy Treasure Moments',
  '学饱玩偶集成了专业咨询、日程管理、天气查询等多功能服务，简化了日常生活，提升了工作效率。根据不同家庭成员的需求提供个性化服务，确保每项任务都能及时、有序地完成，让日常管理变得轻松便捷。':
    'The XUEBAO doll, as a considerate little companion at home, possesses an extraordinary memory like a human being, remembering every detail of daily life and cherishing every highlight moment in everyday experiences.',
  '学饱玩偶将通过日常对话捕捉家庭成员的独特特征，基于这些“画像”，提供更加个性化的互动体验，让每个家庭成员都感受到被特别对待。':
    'The XUEBAO doll, as a considerate little companion at home, possesses an extraordinary memory like a human being, remembering every detail of daily life and cherishing every highlight moment in everyday experiences.',
  '记录欢笑  珍藏点滴时刻': 'Multi-scenario Conversations',
  '学饱玩偶作为家里的的贴心小伙伴，拥有人类一般的超强记忆力，将日常生活中的点点滴滴记在脑海，珍藏日常生活中的每个高光时刻。':
    'The XUEBAO doll integrates professional consultation, schedule management, weather inquiry, and other multifunctional services, simplifying daily life and enhancing work efficiency. It provides personalized services according to the needs of different family members, ensuring that every task is completed promptly and orderly, making daily management easy and convenient.',
  '学饱玩偶能够在谈话时自然的回忆起上次家庭聚会时的趣事，或是提起某个家庭成员的生日，甚至是那次户外旅行中的欢笑。也能够根据家庭成员的喜好和过往的对话，引发新的话题，让每一次的交流都充满新鲜感和亲切感。':
    "The XUEBAO doll can naturally recall the fun stories from the last family gathering during conversations, or mention a family member's birthday, or even the laughter from that outdoor trip. It can also initiate new topics based on the family members' preferences and past conversations, making every interaction fresh and intimate.",
  让学饱玩偶: 'XUEBAO doll',
  '成为你的快乐源泉!': 'your source of joy!',
  '学饱，智能语音互动玩偶，将人工智能的力量带入您的日常生活，随时与您交流并提供帮助。':
    'XUEBAO the intelligent voice interactive doll, brings the power of artificial intelligence into your daily life, ready to communicate and assist you at any time.',
  'Copyright © 2024 . 厦门绽放好物科技有限公司版权所有': 'Copyright © 2024 . Xiamen Blooming Haowu Technology Co.,Ltd.',
  '联系我们：${email}': 'Contact us at ${email}',
};
