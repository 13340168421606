// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/.pnpm/@umijs+runtime@3.5.42_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
    "title": "学饱",
    "exact": true
  },
  {
    "path": "/docs/service-protocol/cn",
    "name": "会员服务协议",
    "title": "会员服务协议",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__service-protocol__cn' */'@/pages/docs/service-protocol/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/docs/auto-renewal/cn",
    "name": "会员自动续费服务协议",
    "title": "会员自动续费服务协议",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__auto-renewal__cn' */'@/pages/docs/auto-renewal/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/docs/privacy-policy/cn",
    "name": "隐私政策",
    "title": "隐私政策",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacy-policy__cn' */'@/pages/docs/privacy-policy/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/docs/refund-instructions/cn",
    "name": "退款须知",
    "title": "退款须知",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__refund-instructions__cn' */'@/pages/docs/refund-instructions/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/docs/user-agreement/cn",
    "name": "用户协议",
    "title": "用户协议",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__user-agreement__cn' */'@/pages/docs/user-agreement/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/docs/logout-instructions/cn",
    "name": "注销须知",
    "title": "注销须知",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__logout-instructions__cn' */'@/pages/docs/logout-instructions/cn'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/*",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "title": "NOT FOUND",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
