import { setLocale as umiSetLocale, getLocale as umiGetLocale, history } from 'umi';
import enUS from './en-US';
import zhCN from './zh-CN';

export type AVAILABLE_KEY = 'zh-CN' | 'en-US';
type LOCALE_PKG = Record<string, string>;
type AVAILABLE_ID = string | React.ReactNode | (string | React.ReactNode)[];
interface OPTIONS {
  values?: Record<string, any>;
}

const getQueryLocale = () => history.location.query.locale as AVAILABLE_KEY;
const getPathName = () => history.location.pathname;
const getLanguageLocale = () => {
  if (getPathName()?.startsWith('/f/')) {
    return getQueryLocale() || window.navigator.language;
  }
  return getQueryLocale() || umiGetLocale();
};

class Locale {
  private localeMaps: Record<AVAILABLE_KEY, LOCALE_PKG> = {
    'zh-CN': zhCN,
    'en-US': enUS,
  };

  private localePkg: LOCALE_PKG = this.localeMaps['en-US'];

  constructor() {
    this.localePkg = this.localeMaps[this.getLocale()];
  }

  setLocale: (locale: AVAILABLE_KEY, realReload: boolean) => void = (locale, realReload) => {
    if (getQueryLocale()) {
      history.replace(getPathName());
    }
    umiSetLocale(locale, realReload);
  };

  getLocale: (locale?: string) => AVAILABLE_KEY = (locale = getLanguageLocale()) => {
    const l = locale.toLowerCase();
    const fallbackMap = [
      { key: 'zh-CN', is: () => l.startsWith('zh-') || l === 'zh' },
      { key: 'en-US', is: () => true },
    ];
    return (fallbackMap.find(_ => _.is()) as { key: string }).key as AVAILABLE_KEY;
  };

  private translateStr: (id: string, options?: OPTIONS) => string = (id, options = {}) => {
    const { values = {} } = options;
    let str = this.localePkg[id] || id;
    Object.keys(values).forEach(key => {
      const keyword = `\\\${${key}}`;
      str = str.replace(new RegExp(keyword, 'g'), values[key]);
    });
    return str;
  };

  s: (id: AVAILABLE_ID, options?: OPTIONS) => AVAILABLE_ID = (id, options) => {
    if (Array.isArray(id)) {
      return id.map(_ => this.s(_, options));
    }
    if (typeof id !== 'string') {
      return id;
    }
    return this.translateStr(id, options);
  };
}

const localeInstance = new Locale();

const getLocale = localeInstance.getLocale.bind(localeInstance);
const setLocale = localeInstance.setLocale.bind(localeInstance);
const s = localeInstance.s.bind(localeInstance);

export { getLocale, setLocale, s };
